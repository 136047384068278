import { TranslationManager } from "../Translation/Translation";

// Time
export function formatTime(date: Date | string | undefined | null): string {
  if (date === undefined || date === null) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}

export function formatTimeWithDate(
  date: Date | string | undefined | null
): string {
  if (date === undefined || date === null) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }

  const now = new Date();
  const diffInHours = Math.abs(now.getTime() - date.getTime()) / 1000 / 60 / 60;

  if (diffInHours < 24) {
    // If the date is within the last 24 hours, display the time
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  } else {
    // If the date is more than 24 hours ago, display the date
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}.${month}.${year}`;
  }
}
export const formatDetailedTime = (date: Date | string | undefined | null) => {
  //return time in format dd.mm.yyyy hh:mm:ss
  if (date === undefined || date === null) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

const getLocale = () =>
  TranslationManager.getActiveLanguage() === "IS" ? "is-IS" : "en-US";

export function formatNumber(
  num: number | undefined | null,
  fractionDigits?: number
): string {
  let decimalPlaces = fractionDigits;
  if (
    decimalPlaces === undefined &&
    num !== undefined &&
    num !== null &&
    !Number.isInteger(num)
  ) {
    //max 4 decimal places
    decimalPlaces = Math.min((num.toString().split(".")[1] || "").length, 4);
  }

  return (
    num?.toLocaleString(getLocale(), {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimalPlaces,
    }) ?? ""
  );
}

export const formatChartNumber = (
  num: number | null | undefined,
  fallBackSymbol: string | null = "-",
  fractionDigits: number = 2,
  suffix = ""
) => {
  if (num === null || num === undefined || isNaN(num)) {
    return fallBackSymbol;
  }

  const formattedNumber = new Intl.NumberFormat(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits,
  }).format(num);
  return formattedNumber + suffix;
};

export function formatWithLetters(num: number): string {
  if (num > 1_000_000_000) return `${Math.round(num / 1_000_000_000)}B`;
  if (num > 1_000_000) return `${Math.round(num / 1_000_000)}M`;
  if (num > 1_000) return `${Math.round(num / 1_000)}K`;
  else return `${Math.round(num)}`;
}

export function formatPrecent(num: number): string {
  return `${Math.round(num * 10000) / 100}%`;
}

export function formatTradeID(id: number | string): string {
  if (typeof id === 'string' && id.length >= 9) {
    return id;
  } else {
    return id.toString().padStart(9, '0');
  }
}
