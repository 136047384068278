export type TradingComponentNames =
  | "ViewOrders"
  | "ViewTrades"
  | "BulkOrderShift";
export type ComponentName =
  | "MarketDepth"
  | "TradeTicker"
  | "WatchList"
  | "Chart"
  | "News"
  | "PriceLadder"
  | "ViewOrders"
  | "ViewTrades"
  | "BulkOrderShift";

export const ComponentNameList: ComponentName[] = [
  "MarketDepth",
  "TradeTicker",
  "WatchList",
  "Chart",
  "News",
  "PriceLadder",
];
export const TradingComponentNameList: ComponentName[] = [
  "ViewOrders",
  "ViewTrades",
  // "BulkOrderShift",
];

export type LevelInfo = {
  b_trader?: string;
  b_orders?: number;
  b_volume?: number;
  b_yield?: number;
  b_dirty?: number;
  b_price?: number;
  a_price?: number;
  a_dirty?: number;
  a_yield?: number;
  a_volume?: number;
  a_orders?: number;
  a_trader?: string;

  a_key?: number;
  b_key?: number;

  sortingTime?: string;
};

export type HeaderInfo = {
  last_price;
  previous_closing_price;
  bid_price;
  intraday_mid_price;
  ask_price;
  intraday_high_price;
  intraday_low_price;
  intraday_accumulated_volume;
};

/*
const HeaderColumns = [
    "previous_closing_price",
    "last_price",
    "change",
    "change_precent",
    "bid_price",
    "intraday_mid_price",
    "ask_price",
    "intraday_high_price",
    "intraday_low_price",
    "intraday_accumulated_volume"
];
*/
