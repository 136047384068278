import { BehaviorSubject } from "rxjs";
import { Config } from "./Config/Config";
import { EnvironmentType } from "./Config/Environment";
import { LMDInterface, SubscriptionLevel } from "./KodiInterface/LMD";
import { filter, first } from 'rxjs/operators';
import { WebsocketConnectionData } from "./Types/LMDTypes";
import Bugsnag from "@bugsnag/js";
import { PDSManagerHandlers, PDSManagerRecord } from "./KodiInterface/WebSocket/PDSWebsocketManager";
import { MFManagerRecord, WSManagerHandlers } from "./KodiInterface/WebSocket/MarketFeedWebsocketManager";

interface systemProfile {
    access: WebsocketConnectionData[];
    system: string;
    username: string;
}
const subscriptionKEY = "private_data_streamer_dma";

// Here should all logic go for needing to start
// The app and refresh it, such as when loging in
// Or when changing websockets
export class AppStateManager {
    static logedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    static tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject(null as string | null);
    static MF: MFManagerRecord = new MFManagerRecord();
    static PDS: PDSManagerRecord = new PDSManagerRecord();
    static hasAccessToPDS: boolean = false;

    static setBackendConnectionType(type: EnvironmentType) {
        Config.changeBackendType(type as 'production' | 'staging');
    }

    // Will always return the newest token and wait if the token has not been initialized yet
    static async getToken(): Promise<string> {
        return new Promise(resolve => AppStateManager.tokenSubject.pipe(
            filter(token => token !== null), first()
        ).subscribe(token => resolve(token as string)));
    }

    static userProfile: systemProfile;
    static subscriptionLevel: SubscriptionLevel;

    static async login(token: string, storeToken: boolean = true) {
        if (storeToken) localStorage.setItem(Config.API_TOKEN_COOKIE, token);
        AppStateManager.tokenSubject.next(token);
        const systemProfile = await LMDInterface.getSystemProfile();
        if (systemProfile) Bugsnag.addOnError((event) => {
            event.addMetadata('user data', { token: AppStateManager.tokenSubject.getValue(), ...systemProfile });
        });
        if ('error' in systemProfile && systemProfile['error'] === "Token expired") this.logout()
        // Schedule the logout at 3AM
        this.scheduleLogoutAtThreeAM();
        // throw new Error(systemProfile['error']);
        AppStateManager.userProfile = systemProfile;
        //check if user has access to real time market data
        if (systemProfile.access.length === 0 || systemProfile.access.filter(x => x.type === "market_data").length === 0) throw new Error("no_access");
        //connecting marketfeed websocket

        //connecting MF websocket
        if (!(systemProfile.access.length === 0 || systemProfile.access.filter(x => x.type === "market_data").length === 0)) {
            AppStateManager.MF.initial(token);
        };
        // const subscriptionLevel = await LMDInterface.getSystemSubscription();
        const subscriptionLevel = await LMDInterface.getSystemSubscription();
        // AppStateManager.hasAccessToPDS = subscriptionLevel.subscriptions.includes(subscriptionKEY);
        AppStateManager.hasAccessToPDS = false;
        //connecting pds websocket
        if (AppStateManager.hasAccessToPDS && !(systemProfile.access.length === 0 || systemProfile.access.filter(x => x.type === "private_data").length === 0)) {
            AppStateManager.PDS.initial(token);
        };

        // if('error' in subscriptionLevel && subscriptionLevel['error'] === "Token expired")this.logout()
        // throw new Error(subscriptionLevel['error']);
        AppStateManager.subscriptionLevel = subscriptionLevel;
        AppStateManager.logedIn.next(true);
    }

    static async logout() {
        localStorage.removeItem(Config.API_TOKEN_COOKIE);

        // This is so MUCH MUCH better then to try to manage a complex state
        // So don't change to a state managed login unles you have a very good
        // reason. This makes everything simpler.
        window.location.href = "/login";

        // This is the old managed state approach
        //GLWindowManager.destroy();
        //this.WSManager.disconnect();
        //AppStateManager.logedIn.next(false);
    }
    static scheduleLogoutAtThreeAM() {
        const now = new Date();
        let threeAM = new Date(now);
        threeAM.setHours(3, 0, 0, 0);

        // If it's already past 03:00, schedule for next day
        if (now > threeAM) {
            threeAM.setDate(threeAM.getDate() + 1);
        }

        let diffInSeconds = Math.floor((threeAM.getTime() - now.getTime()) / 1000);

        const interval = setInterval(() => {
            diffInSeconds--;

            if (diffInSeconds < 0) {
                clearInterval(interval);
                this.logout();
            }
        }, 1000);
    }
}



export function getHandler<T extends keyof WSManagerHandlers>(type: T): WSManagerHandlers[T] {
    return AppStateManager.MF.getHandler(type);
}

export function getPDSHandler<T extends keyof PDSManagerHandlers>(type: T): PDSManagerHandlers[T] {
    return AppStateManager.PDS.getHandler(type);
}